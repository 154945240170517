import { AttributeName, ICartItem, IChainFilter, ILocationFilter, INft, TraitName } from 'types';

export const createAttributesMap = (nfts: INft[]) => {
  const allAttributeNames: AttributeName[] = [
    ...new Set(nfts.flatMap(({ nftAttributes }) => Array.from(nftAttributes.keys()))),
  ];

  return allAttributeNames.reduce((attributesMap, attributeName) => {
    const traitNames: TraitName[] = [...new Set(nfts.map(({ nftAttributes }) => nftAttributes.get(attributeName)!))];
    const traitsCount = traitNames.reduce((traitsMap, traitName) => {
      const nftsWithSpecificTrait = nfts.filter(({ nftAttributes }) => {
        return nftAttributes.get(attributeName) === traitName;
      });

      return traitsMap.set(traitName, nftsWithSpecificTrait.length);
    }, new Map<TraitName, number>());

    return attributesMap.set(attributeName, traitsCount);
  }, new Map<AttributeName, Map<TraitName, number>>());
};

export const filterNfts = (
  nfts: INft[],
  attributeFilters: Map<string, Set<string>>,
  nameFilter: string,
  idsFilter: string[] | undefined,
  locationFilter: ILocationFilter,
  chainFilter: IChainFilter,
  additionalTagFilter?: 'vault' | 'wallet'
) => {
  const anyFilterActive =
    nameFilter.length > 0 ||
    idsFilter != null ||
    attributeFilters.size > 0 ||
    Object.values(locationFilter).some((locationFiltered) => !locationFiltered) ||
    Object.values(chainFilter).some((chainFiltered) => !chainFiltered);

  const nftsFilteredByTag = additionalTagFilter
    ? nfts.filter(
        (nft) => nft.type === 'random' || nft.location.tag === additionalTagFilter || nft.location.tag === 'onBridge'
      )
    : nfts;

  return anyFilterActive
    ? nftsFilteredByTag.filter((nft) => {
        if (nft.type === 'random') {
          return false;
        }

        if (nameFilter.length > 0 && !nft.name.includes(nameFilter)) {
          return false;
        }

        if (idsFilter != null && !idsFilter.includes(nft.id.toString())) {
          return false;
        }

        if (attributeFilters.size > 0) {
          for (const filterEntry of attributeFilters.entries()) {
            const filterSet = filterEntry[1];
            const nftAttributeValue = nft.nftAttributes.get(filterEntry[0]) ?? '';
            if (!filterSet.has(nftAttributeValue)) {
              return false;
            }
          }
        }

        if (
          (nft.location.tag === 'L1' && !locationFilter.l1) ||
          (nft.location.tag === 'shimmerSeaMarket' && !locationFilter.shimmerSea) ||
          (nft.location.tag === 'vault' && !locationFilter.vault) ||
          (nft.location.tag === 'foreign_wallet' && !locationFilter.foreignWallet) ||
          (nft.location.tag === 'wallet' && !locationFilter.wallet)
        ) {
          return false;
        }

        const filteredChains = Object.entries(chainFilter)
          .filter(([_, chainFilter]) => chainFilter)
          .map(([chainName, _]) => chainName);
        if (nft.location.tag !== 'L1' && filteredChains.length > 0 && !filteredChains.includes(nft.location.chain)) {
          return false;
        }

        return true;
      })
    : [...nftsFilteredByTag];
};

export const groupCartItemsByCollectionId = (cartItems: ICartItem[]) => {
  return cartItems.reduce((map, cartItem) => {
    const collectionId = cartItem.nft.collection.id;
    const collectionNfts = map.get(collectionId) ?? [];
    return map.set(collectionId, [...collectionNfts, cartItem]);
  }, new Map<number, ICartItem[]>());
};

export const calculatePriceAndFee = (nft: INft, quantity: number, mode: 'buy' | 'sell') => {
  const collectionPrice = nft.collection.price ?? 0;
  const price =
    mode === 'buy'
      ? (collectionPrice + (nft.type === 'random' ? nft.collection.buyRandomFee : nft.collection.buyFee)) * quantity
      : (collectionPrice - nft.collection.sellFee) * quantity;

  const fee =
    mode === 'buy'
      ? nft.type === 'random'
        ? nft.collection.buyRandomFee
        : nft.collection.buyFee
      : nft.collection.sellFee;

  const feePercentage =
    mode === 'buy'
      ? nft.type === 'random'
        ? nft.collection.buyRandomFeePercentage
        : nft.collection.buyFeePercentage
      : nft.collection.sellFeePercentage;

  return { price, fee, feePercentage };
};
