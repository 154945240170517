import Big from 'big.js';
import { useRcpBucket } from 'contexts/RcpBucket';
import React, { useCallback, useState } from 'react';
import { Address } from 'viem';
import { readContract } from 'viem/actions';
import { useClient } from 'wagmi';
import { mainChain } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

const useAccumulatorHoldings = (props: { address?: Address }) => {
  const client = useClient({ chainId: mainChain.id });
  const { throttleRequest } = useRcpBucket();

  const [holdings, setHoldings] = React.useState<Big>(Big(0));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getSmrAPEinLPBalance = useCallback(
    async (address: Address) => {
      if (!contracts.accumulatorSMRAPEin || !client) {
        return Big(0);
      }

      await throttleRequest();
      const pricePerFullShare = await readContract(client, {
        ...contracts.accumulatorSMRAPEin,
        functionName: 'getPricePerFullShare',
      });

      await throttleRequest();
      const balance = await readContract(client, {
        ...contracts.accumulatorSMRAPEin,
        functionName: 'balanceOf',
        args: [address],
      });

      return Big(pricePerFullShare.toString()).mul(Big(balance.toString())).div(Big(10).pow(36));
    },
    [client, throttleRequest]
  );

  const refresh = useCallback(async (): Promise<void> => {
    if (!props.address || !contracts.accumulatorSMRAPEin) {
      setHoldings(Big(0));
      return;
    }

    try {
      setIsLoading(true);
      const newHoldings = await getSmrAPEinLPBalance(props.address);
      setHoldings(newHoldings);
      setIsError(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [props.address, getSmrAPEinLPBalance]);

  React.useEffect(() => {
    const doIt = async () => {
      await refresh();
    };

    doIt().catch((e) => console.error(e));
  }, [refresh]);

  return {
    holdings,
    isLoading,
    isError,
    refresh,
    getSmrAPEinLPBalance,
  };
};

export default useAccumulatorHoldings;
