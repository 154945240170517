import { ILeaderboardEntry, ILeaderboardResponse } from 'helpers/backendApi/leaderboard_v1';
import { httpGet } from 'helpers/fetch';
import { isDevMode } from 'helpers/storageHelpers';
import { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';

const leaderboardURL = isDevMode()
  ? 'http://localhost:8000/v1/leaderboard'
  : 'https://backend.apedao.finance/v1/leaderboard';

function useLeaderboard() {
  const [entries, setEntries] = useState<(ILeaderboardEntry & { rank: number })[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const updateEntries = () => {
    setIsLoading(true);
    httpGet<ILeaderboardResponse>(leaderboardURL)
      .then((response) => {
        const newEntries = response.parsedBody?.current.leaderboard ?? [];
        setEntries(
          newEntries
            .filter((entry) => parseInt(entry.points) > 0)
            .map((entry, index) => ({ ...entry, rank: index + 1 }))
        );
      })
      .catch(() => {
        setEntries([]);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateEntries();
  }, []);

  useInterval(() => {
    updateEntries();
  }, 30 * 60 * 1000);

  return {
    isLoading,
    error,
    leaderboardEntries: entries,
  };
}

export default useLeaderboard;
