import { StarIcon } from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';
import classNames from 'classnames';
import { Link, PageTemplate, Spinner } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useWalletInfo } from 'contexts/WalletInfo';
import { formatHelpers, useWindowSize } from 'helpers';
import { useIds } from 'helpers/idHelpers';
import { COLLECTIONS } from 'helpers/useNftCollections';
import links from 'links';
import getIds from 'pages/Home/getIds';
import { mainChain } from 'web3/chainsAndWallets';
import useLeaderboard from './hooks';

export interface ILeaderboardProps extends IBaseComponentProps {}

const columns = [
  {
    name: 'Rank',
    className: 'text-center',
  },
  {
    name: 'Address',
    className: 'text-left',
  },
  { name: 'Points', className: 'text-center' },
];

function Leaderboard(props: ILeaderboardProps) {
  const ID = useIds(props.id, getIds);
  const walletInfo = useWalletInfo();
  const { isWidthSmallerThan } = useWindowSize();
  const { isLoading, error, leaderboardEntries } = useLeaderboard();

  const yourEntry = walletInfo.connected
    ? leaderboardEntries.find(({ address }) => address.toLowerCase() === walletInfo.address?.toLowerCase())
    : undefined;

  return (
    <PageTemplate walletInfo={walletInfo} isLoading={isLoading} centerChildren={isLoading} scrollToTopButton={true}>
      {isLoading ? (
        <div className="flex h-40 w-40 flex-col items-center justify-center rounded-full bg-amber-300 opacity-50 dark:opacity-80">
          <Spinner className="h-20 w-20" variant="jumping_ape" />
          <span className="-translate-y-2 text-base font-bold uppercase tracking-wider text-apedao-black-950">
            Loading
          </span>
        </div>
      ) : (
        <div id={ID.root} className="mx-5 flex flex-col items-center py-10">
          <Typography variant="h6" className="uppercase">
            Leaderboard
          </Typography>
          <Typography variant="small" className="text-center">
            Updates 4 times a day
          </Typography>

          {error ? (
            <span className="mt-10 max-w-lg rounded-lg border border-amber-500/70 px-4 py-2 text-center font-normal dark:font-light">
              Failed to fetch leaderboard.
              <br />
              Please refresh or retry later.
            </span>
          ) : (
            <>
              <div className="mt-5 flex max-w-md flex-col items-center gap-3 rounded-md border border-apedao-black-100 p-5 text-center dark:border-apedao-black-700">
                {yourEntry && (
                  <div className="flex flex-row justify-center gap-3">
                    <StarIcon className="h-6 w-6 text-amber-500"></StarIcon>
                    <Typography>
                      Your Rank is <span className="font-semibold">{yourEntry.rank}</span>
                    </Typography>
                    <StarIcon className="h-6 w-6 text-amber-500"></StarIcon>
                  </div>
                )}
                <span className="text-[0.8em] font-light tracking-wider sm:text-[0.9em]">
                  The leaderboard is based on points. You can gather them by holding OG Apes (
                  {COLLECTIONS.get(0)?.price}
                  ), Lil' Apes ({COLLECTIONS.get(1)?.price}) or APEin in ShimmerSea{' '}
                  <Link href={links.shimmerSeaLP}>LP</Link>/<Link href={links.shimmerSeaFarm}>Farm</Link> or{' '}
                  <Link href={links.accumulator}>Accumulator</Link>.
                </span>
              </div>
              <table className="mt-5 table-auto">
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th
                        key={column.name}
                        className={classNames(
                          'border-b-2 border-apedao-black-100 px-4 py-3 dark:border-apedao-black-600 sm:px-7',
                          column.className
                        )}
                      >
                        {column.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="font-mono">
                  {leaderboardEntries.map(({ rank, address, points }, index) => {
                    const isLast = index === leaderboardEntries.length - 1;
                    const isConnectedAddress = address.toLowerCase() === walletInfo.address?.toLowerCase();
                    const className = isLast
                      ? 'py-3 px-4 sm:px-7 text-sm'
                      : 'py-3 px-4 sm:px-7 border-b border-apedao-black-100 dark:border-apedao-black-700 text-sm';

                    return (
                      <tr key={`entry_${rank}`}>
                        <td
                          className={classNames(className, columns[0].className, {
                            'bg-amber-300 text-apedao-black-950 dark:bg-amber-200': isConnectedAddress,
                          })}
                        >
                          {rank}
                        </td>
                        <td
                          className={classNames(className, columns[1].className, {
                            'bg-amber-300 dark:bg-amber-200': isConnectedAddress,
                          })}
                        >
                          <Link
                            href={`${mainChain.blockExplorers.default.url}/address/${address}`}
                            className={classNames({ 'text-blue-900 dark:text-blue-800': isConnectedAddress })}
                          >
                            {isWidthSmallerThan('md') ? formatHelpers.shortenAddress(address) : address}
                          </Link>
                        </td>
                        <td
                          className={classNames(className, columns[2].className, {
                            'bg-amber-300 text-apedao-black-950 dark:bg-amber-200': isConnectedAddress,
                          })}
                        >
                          {points}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </PageTemplate>
  );
}

export default Leaderboard;
