import Big from 'big.js';
import { AssetDisplay, StatsCard } from 'components';
import { IStats } from 'helpers/backendApi/stats_v1';
import { formatBig } from 'helpers/formatHelpers';
import useStatsPrices from './hooks/useStatsPrices';

export interface IStatsPricesProps {
  stats: IStats;
  isDarkMode: boolean;
}

const getDelimiter = (key: string) => <div key={key} className="h-[1em]" />;

export function StatsPrices(props: IStatsPricesProps) {
  const data = useStatsPrices(props);

  if (!data) {
    return <div></div>;
  }

  return (
    <StatsCard title="Prices">
      <div className="flex flex-row gap-4 sm:gap-6">
        <div className="flex grow flex-col justify-end gap-2 pr-4 sm:gap-1">
          <span className="whitespace-nowrap font-bold">Token / NFT</span>
          {data.map((p, idx) =>
            p === 'delimiter' ? (
              getDelimiter('d_token_' + idx)
            ) : p.isToken ? (
              <AssetDisplay key={p.name} name={p.name} />
            ) : (
              <span key={p.name}>{p.name}</span>
            )
          )}
        </div>
        <div className="flex flex-col items-end gap-2 sm:gap-1">
          <span className="whitespace-nowrap font-bold">USD</span>
          {data.map((p, idx) =>
            p === 'delimiter' ? (
              getDelimiter('d_usd_' + idx)
            ) : (
              <div key={p.name} className="flex w-full flex-row sm:gap-2">
                <div className="grow text-end font-mono">
                  {p.usd.gt(Big(0)) ? formatBig(p.usd, p.usdDecimals) : '-'}
                </div>
              </div>
            )
          )}
        </div>
        <div className="flex flex-col items-end gap-2 sm:gap-1">
          <span className="whitespace-nowrap font-bold">SMR</span>
          {data.map((p, idx) =>
            p === 'delimiter' ? (
              getDelimiter('d_smr_' + idx)
            ) : (
              <div key={p.name} className="flex w-full flex-row sm:gap-2">
                <div className="grow text-end font-mono">
                  {p.smr.gt(Big(0)) ? formatBig(p.smr, p.smrDecimals) : '-'}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </StatsCard>
  );
}

export default StatsPrices;
