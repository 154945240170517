import Big from 'big.js';
import { GraphQLClient, gql } from 'graphql-request';
import { ACTIVE_COLLECTIONS } from 'helpers/useNftCollections';
import { useCallback, useEffect, useState } from 'react';
import { IBaseCollection } from 'types';
import { Address } from 'viem';

export interface ICollectionMarketListing {
  collectionId: number;
  nftEdition: number;
  currentAskPrice: Big;
  currentSeller: Address;
}

const shimmerSeaShimmerNftMarketSubgraphUrl =
  'https://graph-c-ha.shimmersea.finance/subgraphs/name/shimmersea/shimmer-nft-market';

const useShimmerSeaNftMarket = (props?: { fetchOnInit?: boolean }) => {
  const [nftsOnMarket, setNftsOnMarket] = useState<Map<number, ICollectionMarketListing[]>>();

  const getNftsOnMarket = useCallback(async (collection: IBaseCollection): Promise<ICollectionMarketListing[]> => {
    if (!collection.contracts.shimmerEvm) {
      return [];
    }

    const query = gql`
    {
      collection(id: "${collection.contracts.shimmerEvm.address.toLowerCase()}") {
        id
        name
        nfts(orderBy: currentAskPrice, where: {currentAskPrice_gt: 0}) {
          id
          currentAskPrice
          currentSeller
        }
      }
    }
  `;

    const client = new GraphQLClient(shimmerSeaShimmerNftMarketSubgraphUrl);
    const data = await client.request<{
      collection: {
        nfts: {
          id: string;
          currentAskPrice: string;
          currentSeller: Address;
        }[];
      } | null;
    }>(query);

    return (data.collection?.nfts ?? []).map((nftListing) => ({
      collectionId: collection.id,
      nftEdition: Number(nftListing.id.split('-')[1]),
      currentAskPrice: Big(nftListing.currentAskPrice),
      currentSeller: nftListing.currentSeller,
    }));
  }, []);

  useEffect(() => {
    if ((props?.fetchOnInit ?? true) && nftsOnMarket == null) {
      Promise.all(Array.from(ACTIVE_COLLECTIONS.values()).map(getNftsOnMarket)).then((results) => {
        const nftsOnMarket = new Map<number, ICollectionMarketListing[]>();
        results.forEach((nfts, index) => {
          const collectionId = [...ACTIVE_COLLECTIONS.values()][index].id;
          nftsOnMarket.set(collectionId, nfts);
        });
        setNftsOnMarket(nftsOnMarket);
      });
    }
  }, [getNftsOnMarket, nftsOnMarket, props?.fetchOnInit]);

  return {
    nftsOnMarket,
    getNftsOnMarket,
  };
};

export default useShimmerSeaNftMarket;
