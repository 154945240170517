import { WalletIcon } from '@heroicons/react/24/outline';
import { ReactComponent as ShimmerSeaOctopus } from 'assets/images/shimmersea_octopus.svg';
import classNames from 'classnames';
import { ApeDaoIcon, FadeInOut, Link } from 'components';
import { formatHelpers } from 'helpers';
import links from 'links';
import { INft } from 'types';
import { Address } from 'viem';
import useNftInfoDialogLocation from './hooks/useNftInfoDialogLocation';

function NftInfoDialogLocation(props: { nft?: INft }) {
  const { explorerLink } = useNftInfoDialogLocation({
    location: props.nft?.location,
  });

  if (!props.nft?.location?.tag) {
    return null;
  }

  const nftAddressKnown = props.nft?.location?.address != null && props.nft?.location?.address !== 'n/a';

  return (
    <FadeInOut isVisible={props.nft?.location.tag !== 'wallet' && props.nft?.location.tag !== 'n/a'}>
      <div
        className={classNames(
          'flex flex-col justify-center rounded-md border border-apedao-black-100 p-1.5 text-sm shadow-sm md:p-3',
          'dark:border-apedao-black-500 dark:bg-none'
        )}
      >
        <div className="relative flex flex-row items-center gap-3 pr-4">
          {props.nft?.location.tag === 'L1' ? (
            <>
              <div className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-md border border-apedao-black-300 pt-0.5 font-normal text-apedao-black-300 sm:h-6 sm:w-6">
                L1
              </div>
              <span className="overflow-auto break-words font-mono text-xs font-light dark:font-thin sm:text-sm">
                The NFT has not been migrated to L2 yet.
              </span>
            </>
          ) : props.nft?.location.tag === 'shimmerSeaMarket' ? (
            <>
              <ShimmerSeaOctopus className="ml-1 h-5 w-5 flex-shrink-0 fill-blue-300 dark:fill-apedao-black-300" />
              <span className="overflow-auto break-words font-mono text-xs font-light dark:font-thin sm:text-sm">
                Offered on{' '}
                {props.nft.collection.contracts.shimmerEvm ? (
                  <Link
                    href={`${links.shimmerSea}/nfts/collections/${props.nft.collection.contracts.shimmerEvm.address}/${props.nft.edition}`}
                  >
                    ShimmerSea
                  </Link>
                ) : (
                  'ShimmerSea'
                )}{' '}
                {nftAddressKnown && (
                  <>
                    by{' '}
                    <Link href={`${explorerLink}/address/${props.nft?.location.address}`}>
                      {formatHelpers.shortenAddress(props.nft?.location.address as Address)}
                    </Link>
                  </>
                )}
              </span>
            </>
          ) : props.nft?.location.tag === 'vault' ? (
            <>
              <ApeDaoIcon
                className="location-ape-image h-6 w-6 fill-apedao-black-600 dark:fill-apedao-black-300"
                noBackground={true}
              />
              <span className="overflow-auto break-words font-mono text-xs font-light dark:font-thin sm:text-sm">
                This NFT is available on ApeDao marketplace.
              </span>
            </>
          ) : (
            <>
              <WalletIcon className="h-5 w-5 flex-shrink-0 sm:h-6 sm:w-6" strokeWidth={1} />
              <span className="overflow-auto break-words font-mono text-xs font-light dark:font-thin sm:text-sm">
                {nftAddressKnown && (
                  <>
                    {'Owned by '}
                    <Link href={`${explorerLink}`}>
                      {formatHelpers.shortenAddress(props.nft?.location?.address as Address)}
                    </Link>
                  </>
                )}
              </span>
            </>
          )}
        </div>
      </div>
    </FadeInOut>
  );
}

export default NftInfoDialogLocation;
