import { useIsOverflow } from 'helpers';
import { calculateTotalPriceAndFees, isApeInFundsSufficient, isSmrFundsSufficient } from 'helpers/cartHelpers';
import useShimmerSea from 'helpers/useShimmerSea';
import React, { useEffect } from 'react';
import { IAssetAmount, ICart, ICartSums, ICollection, ICollectionApprovals, INft } from 'types';
import { ICartProps } from '../Cart';

export interface IQuantityPerCollection {
  collectionId: number;
  collectionName: string;
  quantity: number;
}

const emptyAmount: IAssetAmount & { error?: string } = { amount: 0n, decimals: 0 };

function useCart(
  { cart, collectionApprovals, walletInfo }: Pick<ICartProps, 'cart' | 'collectionApprovals' | 'walletInfo'>,
  itemsRef: React.RefObject<HTMLElement>
) {
  const [sums, setSums] = React.useState<ICartSums<number>>({ price: 0, fee: 0 });
  const [sumsInSmr, setSumsInSmr] = React.useState<ICartSums<IAssetAmount & { error?: string }>>({
    price: emptyAmount,
    fee: emptyAmount,
  });
  const [quantityPerCollection, setQuantityPerCollection] = React.useState<IQuantityPerCollection[]>([]);
  const [missingCollectionApprovals, setMissingCollectionApprovals] = React.useState<ICollection[]>([]);
  const [itemsIsOverflow, triggerOverflowCalculation] = useIsOverflow(itemsRef);

  const shimmerSea = useShimmerSea();

  useEffect(() => {
    const cartItems = [...cart.items.values()].filter((cartItem) => !cartItem.toBeRemoved);

    const quantityPerCollection = calcQuantityPerCollection(cartItems);
    setQuantityPerCollection(quantityPerCollection);

    const totalPriceAndFees = calculateTotalPriceAndFees(cartItems, cart.mode);
    setSums(totalPriceAndFees);

    const missingApprovals = cart.mode === 'sell' ? getMissingCollectionApprovals(cart, collectionApprovals) : [];
    setMissingCollectionApprovals(missingApprovals);

    triggerOverflowCalculation();
  }, [triggerOverflowCalculation, cart.items, collectionApprovals.approvals, cart, collectionApprovals]);

  const convertToSmrAmount = shimmerSea.convertToSmrAmount;
  useEffect(() => {
    if (cart.mode === 'buy' && cart.buyWithSmr) {
      const doIt = async () => {
        const priceInSmr = sums.price > 0 ? await convertToSmrAmount(sums.price) : emptyAmount;
        const feeInSmr = sums.fee > 0 ? await convertToSmrAmount(sums.fee) : emptyAmount;
        setSumsInSmr({
          price: priceInSmr,
          fee: feeInSmr,
        });
      };

      doIt();
    }
  }, [cart.buyWithSmr, cart.mode, convertToSmrAmount, sums.fee, sums.price]);

  const totalNumberOfItems = [...cart.items.values()].reduce((sum, cartItem) => sum + cartItem.quantity, 0);
  const insufficientFunds =
    cart.mode === 'buy' &&
    (cart.buyWithSmr
      ? walletInfo.nativeBalance != null && !isSmrFundsSufficient(walletInfo.nativeBalance, sumsInSmr.price)
      : walletInfo.apeInBalance != null && !isApeInFundsSufficient(walletInfo.apeInBalance, sums.price));
  const errorFetchAmountsInSmr =
    cart.mode === 'buy' && cart.buyWithSmr && (sumsInSmr.price.error ?? sumsInSmr.fee.error);

  return {
    sums,
    sumsInSmr,
    quantityPerCollection,
    totalNumberOfItems,
    itemsIsOverflow,
    missingCollectionApprovals,
    insufficientFunds,
    errorFetchAmountsInSmr,
    triggerOverflowCalculation,
  };
}

function getMissingCollectionApprovals(cart: ICart, collectionApprovals: ICollectionApprovals) {
  const collectionsInCart = new Set([...cart.items.values()].map((cartItem) => cartItem.nft.collection));
  return [...collectionApprovals.approvals.entries()]
    .filter(([collectionId]) => [...collectionsInCart].find((collection) => collection.id === collectionId))
    .filter(([_, approved]) => !approved)
    .map(([collectionId]) => {
      return [...cart.items.values()].find((cartItem) => cartItem.nft.collection.id === collectionId)?.nft.collection!;
    });
}

export function calcQuantityPerCollection(cartItems: { nft: INft; quantity: number }[]) {
  return cartItems.reduce((acc, cartItem) => {
    const collectionId = cartItem.nft.collection.id;
    const collectionName = cartItem.nft.collection.name;

    const collectionCount = acc.find((count) => count.collectionId === collectionId);
    if (collectionCount) {
      collectionCount.quantity += cartItem.quantity;
    } else {
      acc.push({ collectionId, collectionName, quantity: cartItem.quantity });
    }

    return acc;
  }, [] as IQuantityPerCollection[]);
}

export default useCart;
