import { createIds } from 'helpers/idHelpers';

const getIds = (root: string) => {
  return createIds(
    root,
    'logo',
    'connectBtn',
    'menuConnectBtn',
    'navItems',
    'navItemHome',
    'navItemVaults',
    'navItemMyNFTs',
    'balanceSmr',
    'balanceApeIn'
  );
};

export default getIds;
