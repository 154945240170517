import { msg } from 'helpers';
import { useState } from 'react';
import { ICartItem, ICheckoutCartResult, ICollection } from 'types';
import { mainChain } from 'web3/chainsAndWallets';

export type Error = string;

export interface IOnCheckout {
  onCheckout: (cartItems: ICartItem[]) => Promise<ICheckoutCartResult>;
  isLoading: boolean;
}

const useOnCheckout = (
  doCheckout: (cartItems: ICartItem[], buyWithSmr?: boolean) => Promise<`0x${string}` | false>,
  getCurrentVaultWalletNftIds: (collection: ICollection) => Promise<BigInt[]>,
  successMsgProvider: (cartItems: ICartItem[]) => string,
  errorMsgProvider: (cartItems: ICartItem[]) => string
): IOnCheckout => {
  const [isLoading, setIsLoading] = useState(false);

  const checkAvailability = async (cartItems: ICartItem[]): Promise<ICheckoutCartResult> => {
    const cartItemCollections = new Set(cartItems.map((cartItem) => cartItem.nft.collection));
    const currentVaultWalletNftIdsPerCollection = new Map<number, BigInt[]>();
    for (const collection of cartItemCollections) {
      const currentVaultWalletNftIds = await getCurrentVaultWalletNftIds(collection);
      currentVaultWalletNftIdsPerCollection.set(collection.id, currentVaultWalletNftIds);
    }

    const unavailableNfts = cartItems
      .filter((cartItem) => {
        const currentVaultWalletNftIds = currentVaultWalletNftIdsPerCollection.get(cartItem.nft.collection.id) ?? [];
        return cartItem.nft.type === 'nft' && !currentVaultWalletNftIds.includes(BigInt(cartItem.nft.edition));
      })
      .map((cartItem) => cartItem.nft);

    return {
      success: unavailableNfts.length === 0,
      errors: unavailableNfts.map((nft) => {
        return { nft, cause: 'unavailable' };
      }),
    };
  };

  const onCheckout = async (cartItems: ICartItem[], buyWithSmr?: boolean): Promise<ICheckoutCartResult> => {
    setIsLoading(true);
    let checkoutCartResult: ICheckoutCartResult = { success: false };
    try {
      checkoutCartResult = await checkAvailability(cartItems);

      if (checkoutCartResult.success) {
        const doCheckoutExecuted = await doCheckout(cartItems, buyWithSmr);
        if (doCheckoutExecuted) {
          msg.txHashInfo(
            successMsgProvider(cartItems),
            mainChain.blockExplorers.default.url + '/tx/' + doCheckoutExecuted
          );
        }
        checkoutCartResult.success = doCheckoutExecuted !== false;
      } else {
        msg.error('Some NFTs are not available any longer.', { autoClose: false });
      }
    } catch (error) {
      msg.error(errorMsgProvider(cartItems) + ': ' + (error as any).shortMessage, { autoClose: false });
      console.error(error);
      checkoutCartResult.success = false;
    } finally {
      setIsLoading(false);
    }

    return checkoutCartResult;
  };

  return {
    onCheckout,
    isLoading,
  };
};

export default useOnCheckout;
