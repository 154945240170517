import { usePrevious } from '@material-tailwind/react';
import { useWalletInfo } from 'contexts/WalletInfo';
import { msg, useLayerZeroBridge, useNftTransferEvents } from 'helpers';
import { filterNfts } from 'helpers/nftHelpers';
import { LOCAL_STORAGE_FAVORITES } from 'helpers/storageHelpers';
import useNftLocations from 'pages/VaultsAndWallet/hooks/useNftLocations';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IChainFilter, ICollection, ILocationFilter, INft, INftLocation } from 'types';
import { useDebounce, useLocalStorage } from 'usehooks-ts';
import { EvmChainName, supportedChainsByName } from 'web3/chainsAndWallets';

const initialLocationFilter: ILocationFilter = {
  l1: true,
  vault: true,
  wallet: true,
  shimmerSea: true,
  foreignWallet: true,
};

const initialChainFilter: IChainFilter = Object.keys(supportedChainsByName).reduce((obj, chainName) => {
  obj[chainName as EvmChainName] = true;
  return obj;
}, {} as Record<EvmChainName, true>);

export function useVaultWalletBaseHook(props: {
  mode: 'vault' | 'wallet';
  collection?: ICollection;
  getCurrentBalances: (collection: ICollection) => Promise<Map<EvmChainName, BigInt>>;
  getCurrentHoldings: (collection: ICollection) => Promise<Map<EvmChainName, BigInt[]>>;
  addRandom: boolean;
}) {
  const previousCollection = usePrevious(props.collection);

  const { address: connectedAddress } = useWalletInfo();
  const previousConnectedAddress = usePrevious(connectedAddress);

  const [isLoading, setIsLoading] = React.useState(false);

  const [nfts, setNfts] = React.useState<INft[]>([]);
  const [filteredNfts, setFilteredNfts] = React.useState<INft[]>([]);
  const [favorites, setFavorites] = useLocalStorage<string[]>(LOCAL_STORAGE_FAVORITES, []);

  const [nameFilter, setNameFilter] = React.useState<string>('');
  const debouncedNameFilter = useDebounce(nameFilter, 150);
  const [attributeFilters, setAttributeFilters] = React.useState<Map<string, Set<string>>>(new Map());
  const [onlyFavoritesFilter, setOnlyFavoritesFilter] = React.useState<boolean>(false);
  const [locationFilter, setLocationFilter] = React.useState<ILocationFilter>(initialLocationFilter);
  const [chainFilter, setChainFilter] = React.useState<IChainFilter>(initialChainFilter);
  const [galleryMode, setGalleryMode] = useState<boolean>(false);

  const { getNftLocationsFromBackend, getNftLocation } = useNftLocations();
  const { registerListener: registerTransferEventListener } = useNftTransferEvents({
    collection: props.collection,
  });
  const { nftsOnBridge, setSelectedNftIds, finalizePreviouslyTransferredNfts, ...layerZeroBridgeRest } =
    useLayerZeroBridge({ collection: props.collection, registerTransferEventListener });

  const layerZeroBridgeHandler = useMemo(
    () => ({ nftsOnBridge, setSelectedNftIds, finalizePreviouslyTransferredNfts, ...layerZeroBridgeRest }),
    [finalizePreviouslyTransferredNfts, layerZeroBridgeRest, nftsOnBridge, setSelectedNftIds]
  );

  const setFavorite = useCallback(
    (nftId: string, favorite: boolean) => {
      setFavorites((favoriteNftIds) => {
        const newFavoriteNftIds = favoriteNftIds.filter((favoriteNftId) => favoriteNftId !== nftId);
        if (favorite) {
          newFavoriteNftIds.push(nftId);
        }
        return newFavoriteNftIds;
      });
    },
    [setFavorites]
  );

  const onNameFilterChange = (id: string) => {
    setNameFilter(id);
  };

  const getCurrentHoldings = props.getCurrentHoldings;
  const doPrepareNfts = useCallback(
    async (holdings: Map<EvmChainName, BigInt[]>, collection: ICollection, nftLocations: INftLocation[]) => {
      const newNfts: INft[] = [];
      for (const nft of collection.nfts) {
        const edition = BigInt(nft.edition);
        const chainHoldingEntryWithNft = Array.from(holdings.entries()).find(([_, editions]) =>
          editions.includes(edition)
        );
        const isHoldingOnChain = chainHoldingEntryWithNft != null ? chainHoldingEntryWithNft[0] : undefined;

        if (isHoldingOnChain) {
          // use local information to set location because backend info might be wrong
          newNfts.push({
            ...nft,
            location: {
              chain: isHoldingOnChain,
              address: connectedAddress ?? 'n/a',
              tag: props.mode === 'vault' ? 'vault' : 'wallet',
            },
          });
        } else if (nftsOnBridge[nft.id]) {
          newNfts.push({
            ...nft,
            location: {
              chain: nftsOnBridge[nft.id].to.chainName,
              address: nftsOnBridge[nft.id].to.address,
              tag: 'onBridge',
            },
          });
        } else if (nftLocations.length > 0) {
          const location: INftLocation = nftLocations[nft.edition - 1];
          if (location.tag === props.mode) {
            // use local information as backend seems to deliver wrong information
            const correctLocation = await getNftLocation(nft.id);
            newNfts.push({ ...nft, location: correctLocation });
          } else {
            newNfts.push({ ...nft, location });
          }
        } else {
          newNfts.push({
            ...nft,
            location: {
              chain: 'n/a',
              address: 'n/a',
              tag: 'n/a',
            },
          });
        }
      }

      return newNfts;
    },
    [connectedAddress, getNftLocation, nftsOnBridge, props.mode]
  );

  const refreshHoldings = useCallback(
    async (collection: ICollection, addRandom: boolean) => {
      try {
        console.log('Refreshing vault NFTs for collection ' + collection.name);
        const holdings = await getCurrentHoldings(collection);

        let nftLocations: INftLocation[] = [];
        try {
          nftLocations = await getNftLocationsFromBackend(collection);
        } catch (error) {
          console.error('Error getting NFT locations from backend', error);
        }

        const newNfts = await doPrepareNfts(holdings, collection, nftLocations);
        finalizePreviouslyTransferredNfts(newNfts);

        setNfts(addRandom ? [createRandomNft(collection), ...newNfts] : newNfts);
      } catch (error) {
        console.error(error);
        msg.error(`Error loading vault NFTs`, { autoClose: false });
        setNfts([]);
      }
    },
    [doPrepareNfts, finalizePreviouslyTransferredNfts, getCurrentHoldings, getNftLocationsFromBackend]
  );

  const refresh = useCallback(
    async (setLoading: boolean) => {
      if (props.collection) {
        setIsLoading(setLoading);
        refreshHoldings(props.collection, props.addRandom).finally(() => {
          setIsLoading(false);
        });
      }
    },
    [props.addRandom, props.collection, refreshHoldings]
  );

  useEffect(() => {
    const collectionChanged = props.collection !== previousCollection;
    const connectedAddressChanged = connectedAddress !== previousConnectedAddress;
    const triggerRefresh = collectionChanged || connectedAddressChanged;

    if (props.collection != null && triggerRefresh) {
      setChainFilter(initialChainFilter);
      setLocationFilter(initialLocationFilter);
      setIsLoading(true);
      refreshHoldings(props.collection, props.addRandom).finally(() => {
        setIsLoading(false);
      });
    }
  }, [
    connectedAddress,
    previousCollection,
    previousConnectedAddress,
    props.addRandom,
    props.collection,
    refreshHoldings,
  ]);

  useEffect(() => {
    if (!galleryMode) {
      setLocationFilter(initialLocationFilter);
    }

    if (debouncedNameFilter != null || attributeFilters != null) {
      setFilteredNfts(
        filterNfts(
          nfts,
          attributeFilters ?? new Map(),
          debouncedNameFilter,
          onlyFavoritesFilter ? favorites : undefined,
          locationFilter,
          chainFilter,
          galleryMode ? undefined : props.mode
        )
      );
    }
  }, [
    attributeFilters,
    chainFilter,
    debouncedNameFilter,
    favorites,
    galleryMode,
    locationFilter,
    nfts,
    onlyFavoritesFilter,
    props.mode,
  ]);

  useEffect(() => {
    setNfts((prevState) => {
      let somethingChanged = false;
      const newNfts: INft[] = [];
      for (const nft of prevState) {
        const isOnBridgeToChain = nftsOnBridge[nft.id] ? nftsOnBridge[nft.id].to.chainName : undefined;
        if (isOnBridgeToChain && nft.location.chain !== isOnBridgeToChain) {
          newNfts.push({
            ...nft,
            location: {
              chain: isOnBridgeToChain,
              address: nftsOnBridge[nft.id].to.address,
              tag: 'onBridge',
            },
          });
          somethingChanged = true;
        } else if (!isOnBridgeToChain && nft.location.tag === 'onBridge') {
          newNfts.push({
            ...nft,
            location: {
              ...nft.location,
              tag: props.mode === 'vault' ? 'vault' : 'wallet',
            },
          });
          somethingChanged = true;
        } else {
          newNfts.push(nft);
        }
      }
      return somethingChanged ? newNfts : prevState;
    });
  }, [nftsOnBridge, props.mode]);

  useEffect(() => {
    return registerTransferEventListener((transfer) => {
      getNftLocation(transfer.nftId).then((location) => {
        setNfts((prevNfts) => {
          const index = prevNfts.findIndex((nft) => nft.id === transfer.nftId);
          if (!transfer.onBridge || (transfer.onBridge && prevNfts[index].location.tag !== 'onBridge')) {
            const newNfts = [...prevNfts];
            newNfts[index] = {
              ...newNfts[index],
              location: transfer.onBridge
                ? { ...location, chain: transfer.chain, address: newNfts[index].location.address, tag: 'onBridge' }
                : location,
            };
            return newNfts;
          }
          return prevNfts;
        });
      });
    });
  }, [getNftLocation, registerTransferEventListener]);

  return {
    nfts,
    filteredNfts,
    filters: {
      nameFilter,
      attributeFilters: attributeFilters,
      onlyFavorites: onlyFavoritesFilter,
      locationFilter: locationFilter,
      locationFilterEnabled: galleryMode,
      chainFilter: chainFilter,
      galleryMode: galleryMode,
      onNameFilterChange,
      onAttributeFiltersChange: setAttributeFilters,
      onOnlyFavoritesChange: setOnlyFavoritesFilter,
      onLocationFilterChange: setLocationFilter,
      onChainFilterChange: setChainFilter,
      onSetGalleryMode: setGalleryMode,
    },
    isLoading: isLoading,
    refresh,
    getCurrentVaultWalletNftIds: props.getCurrentHoldings,
    favorites,
    setFavorite,
    layerZeroBridgeHandler,
  };
}

const createRandomNft = (collection: ICollection): INft => ({
  id: `${collection.id}-random`,
  type: 'random',
  collection: collection,
  name: `Random ${collection.name}`,
  edition: -1,
  rank: -1,
  dna: '',
  nftAttributes: new Map(),
  location: {
    chain: 'n/a',
    address: 'n/a',
    tag: 'n/a',
  },
});
