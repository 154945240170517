import { NftInfoDialogProps } from 'components/NftInfoDialog/NftInfoDialog';
import { nftHelpers } from 'helpers';
import useShimmerSeaNftMarket from 'helpers/useShimmerSeaNftMarket';
import useNftLocations from 'pages/VaultsAndWallet/hooks/useNftLocations';
import React, { useCallback, useEffect } from 'react';
import { INft, INftLocation } from 'types';
import { useSwitchChain } from 'wagmi';
import { getChainByEvmChainName, mainChain } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

function useNftInfoDialog(props: NftInfoDialogProps) {
  const { switchChain } = useSwitchChain();

  const [priceDetails, setPriceDetails] = React.useState({
    symbol: '',
    price: 0,
    fee: 0,
    feePercentage: 0,
  });

  const { nftsOnMarket: nftsOnShimmerSeaMarket } = useShimmerSeaNftMarket();

  const { getNftLocation } = useNftLocations();
  const [nftLocation, setNftLocation] = React.useState<INftLocation>();

  const { nftsOnBridge, setTargetChain, supportedTargetChains } = props.lzBridgeHandler ?? {};
  useEffect(() => {
    const doIt = async () => {
      if (props.nft) {
        const nftOnBridge = nftsOnBridge && nftsOnBridge[props.nft.id];
        if (nftOnBridge) {
          setNftLocation({
            chain: nftOnBridge.to.chainName,
            address: nftOnBridge.to.address,
            tag: 'onBridge',
          });
          setTargetChain && setTargetChain(nftOnBridge.to.chainName);
        } else {
          setNftLocation(await getNftLocation(props.nft.id));
          setTargetChain && supportedTargetChains && setTargetChain(supportedTargetChains[0]);
        }
      }
    };
    doIt().catch((e) => console.error(e));
  }, [getNftLocation, nftsOnBridge, props.nft, setTargetChain, supportedTargetChains]);

  useEffect(() => {
    const doIt = async () => {
      if (props.nft && nftsOnShimmerSeaMarket) {
        const collectionNftsOnMarket = nftsOnShimmerSeaMarket.get(props.nft.collection.id) ?? [];
        const marketListing = collectionNftsOnMarket.find(
          (marketListing) => marketListing.nftEdition === props.nft?.edition
        );

        if (marketListing) {
          setPriceDetails({ symbol: 'SMR', price: marketListing.currentAskPrice.toNumber(), fee: 0, feePercentage: 0 });
        } else {
          setPriceDetails({
            symbol: props.nft.collection.symbol,
            ...nftHelpers.calculatePriceAndFee(props.nft, 1, props.mode),
          });
        }
      }
    };
    doIt().catch((e) => console.error(e));
  }, [nftsOnShimmerSeaMarket, props.mode, props.nft]);

  const switchToNetworkOfNft = useCallback(
    (nft: INft) => {
      const chainId = props.nft && getChainByEvmChainName(nft.location.chain)?.id;
      chainId && switchChain && switchChain({ chainId });
    },
    [props.nft, switchChain]
  );

  const showPriceInfo =
    props.nft?.location.chain === mainChain.chainName &&
    (props.nft?.location.address === contracts.shimmerSeaNFTMarket.address ||
      (props.nft?.collection.featureCart && props.mode === 'buy' && props.nft?.location.tag === 'vault') ||
      (props.nft?.collection.featureCart && props.mode === 'sell' && props.nft?.location.tag === 'wallet'));

  return {
    switchToNetworkOfNft,
    nftLocation,
    priceDetails,
    showPriceInfo: showPriceInfo ?? false,
  };
}

export default useNftInfoDialog;
