const abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_apeDAOVault',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_uniswapRouter',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_shimmerERC20',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'SMR',
    outputs: [
      {
        internalType: 'contract IERC20',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'apeDAOVault',
    outputs: [
      {
        internalType: 'contract IApeDAOVault',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amountOGApes',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'amountLilApes',
        type: 'uint256',
      },
      {
        internalType: 'uint256[]',
        name: 'specificIdsOGApes',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'specificIdsLilApes',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256',
        name: 'maxSMRIn',
        type: 'uint256',
      },
      {
        internalType: 'address[]',
        name: 'path',
        type: 'address[]',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
    ],
    name: 'buyAndRedeemSMR',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'uniswapRouter',
    outputs: [
      {
        internalType: 'contract IUniswapV2Router02',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const;

export default abi;
