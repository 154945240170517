import { ChartBarIcon, ChatBubbleLeftRightIcon, ChevronDownIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';
import { Accordion, AccordionBody, AccordionHeader, Button, Typography } from '@material-tailwind/react';
import { ReactComponent as ShimmerSeaImage } from 'assets/images/shimmersea.svg';
import classNames from 'classnames';
import { ApeDaoIcon, PageTemplate, RotatingImages, SocialIcons } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useWalletInfo } from 'contexts/WalletInfo';
import { useDarkMode } from 'helpers';
import { useIds } from 'helpers/idHelpers';
import { shuffleArray } from 'helpers/randomHelpers';
import getIds from 'pages/Home/getIds';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import links from '../../links';
import './Home.css';

export interface IHomeProps extends IBaseComponentProps {}

function HomeValueDescription(props: { icon: React.ReactComponentElement<any>; title: string; description: string }) {
  return (
    <div className="flex flex-col gap-5">
      <div className="h-10 w-10">{props.icon}</div>
      <Typography variant="h5" className="uppercase">
        {props.title}
      </Typography>
      <Typography variant="paragraph">{props.description}</Typography>
    </div>
  );
}

function FaqAccordion(props: {
  title: string;
  description: string | React.JSX.Element;
  open: boolean;
  onClick: () => void;
}) {
  return (
    <Accordion
      open={props.open}
      icon={
        <ChevronDownIcon
          strokeWidth={2}
          className={classNames('h-5 w-5  transition-transform', {
            'rotate-180': props.open,
          })}
        />
      }
    >
      <AccordionHeader onClick={props.onClick}>{props.title}</AccordionHeader>
      <AccordionBody>{props.description}</AccordionBody>
    </Accordion>
  );
}

function FaqLink(props: { url: string; children: React.ReactNode }) {
  return (
    <a href={props.url} target="_blank" rel="noreferrer" className="text-blue-600 hover:underline dark:text-blue-500">
      {props.children}
    </a>
  );
}

function Home(props: IHomeProps) {
  const ID = useIds(props.id, getIds);
  const { darkMode } = useDarkMode();
  const walletInfo = useWalletInfo();
  const [faqAccordionOpen, setFaqAccordionOpen] = React.useState<number>(-1);
  const randomizedHomeImages = useRef(
    shuffleArray([
      '/images/home_1.webp',
      '/images/home_2.webp',
      '/images/home_3.webp',
      '/images/home_4.webp',
      '/images/home_5.webp',
      '/images/home_6.webp',
      '/images/home_7.webp',
      '/images/home_8.webp',
    ])
  );

  return (
    <PageTemplate walletInfo={walletInfo} centerChildren={true}>
      <div id={ID.root} className="mx-7 my-14 flex max-w-[100vw] flex-col sm:mx-10 md:mx-20 md:my-20 md:max-w-[1024px]">
        <div className="flex flex-col items-center gap-5 border-b border-apedao-black-950 dark:border-apedao-black-800 lg:flex-row lg:items-end lg:gap-16">
          <div className="flex flex-col gap-5 lg:w-1/2 lg:py-10">
            <Typography variant="h5" className="uppercase">
              Degenerate Ape Organization
            </Typography>
            <Typography variant="h1" className="uppercase">
              Welcome Home, Degen.
            </Typography>
            <Typography variant="h6">
              We are a small joint-investment DAO in which a randomly-generated Ape NFT makes you a DAO participant.
            </Typography>
            <a href={links.discord} target="_blank" rel="noreferrer">
              <Button variant={darkMode ? 'outlined' : 'filled'} className="mt-4 w-fit">
                Join the culture
              </Button>
            </a>
            <SocialIcons className="gap-2" />
          </div>
          <RotatingImages
            imageURLs={randomizedHomeImages.current}
            imageRotationTimeMs={6000}
            numberOfTiles={10}
            className="max-w-[500px] lg:w-1/2 lg:min-w-[400px]"
          />
        </div>

        <div className="flex flex-col gap-12 border-b border-apedao-black-950 py-12 dark:border-apedao-black-800 lg:gap-20 lg:py-20">
          <div className="flex flex-col gap-5">
            <Typography variant="h5" className="text-center uppercase">
              Transparency. Integrity. Sustainability.
            </Typography>
            <Typography variant="h1" className="text-center uppercase">
              Our Values
            </Typography>
            <Typography variant="h6" className="text-center">
              Building a sustainable DAO in a transparent and honest way by managing our NFT-funded treasury in the best
              way we know.
            </Typography>
          </div>
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-3">
            <HomeValueDescription
              icon={<RocketLaunchIcon />}
              title="Fair Launch"
              description="Whole supply of NFTs was offered for sale - no Apes were held back for the team. 100% of NFT sale was used to form the Treasury."
            />
            <HomeValueDescription
              icon={<ChartBarIcon />}
              title="Underlying Value"
              description="Ape NFT holders benefit from the value streams that we are planning to establish - APEinvest, APEvisory and APEducation."
            />
            <HomeValueDescription
              icon={<ChatBubbleLeftRightIcon />}
              title="Governance"
              description="The plan is to grow the ApeDAO into a fully functioning DAO in respect of decentralization and democratic management by Ape hodlers."
            />
          </div>
          <div className="flex flex-row items-center justify-center gap-5">
            <a href={links.discord} target="_blank" rel="noreferrer">
              <Button variant={darkMode ? 'outlined' : 'filled'} className="w-fit">
                Join the culture
              </Button>
            </a>
            <Link to={links.docs} target="_blank" className="w-fit">
              {'Learn more >'}
            </Link>
          </div>
        </div>

        <div className="flex flex-col gap-12 border-b border-apedao-black-950 py-12 dark:border-apedao-black-800 lg:gap-20 lg:py-20">
          <div className="flex flex-col gap-5">
            <Typography variant="h1" className="text-center uppercase">
              ONE OF US! ONE OF US!
            </Typography>
            <Typography variant="h6" className="text-center">
              Buy an Ape and join the culture.
            </Typography>
          </div>
          <div className="grid-cen grid grid-cols-1 justify-items-center gap-10 lg:grid-cols-2">
            <div className="flex max-w-[90vw] flex-col items-center justify-center gap-10 border border-apedao-black-950 p-10 dark:border-apedao-black-800 sm:max-w-[640px]">
              <ShimmerSeaImage
                className={classNames('shimmersea_image h-20 max-w-[100%]', { shimmersea_image_dark: darkMode })}
              />
              <Typography variant="paragraph" className="text-center">
                ShimmerSea is the leading DEX/AMM & NFT marketplace on Shimmer EVM network.
              </Typography>
              <div className="flex flex-row items-center justify-center gap-5">
                <a href={links.shimmerSeaOgApesMarket} target="_blank" rel="noreferrer">
                  <Button variant={darkMode ? 'outlined' : 'filled'} className="w-fit whitespace-nowrap">
                    Buy OG Apes
                  </Button>
                </a>
                <a href={links.shimmerSeaLilApesMarket} target="_blank" rel="noreferrer">
                  <Button variant={darkMode ? 'outlined' : 'filled'} className="w-fit whitespace-nowrap">
                    Buy Lil' Apes
                  </Button>
                </a>
              </div>
            </div>

            <div className="flex max-w-[90vw] flex-col items-center justify-center gap-10 border border-apedao-black-950 p-10 dark:border-apedao-black-800 sm:max-w-[640px]">
              <div className="flex flex-row items-center gap-2">
                <ApeDaoIcon
                  className={classNames('apedao_image h-16', { apedao_image_dark: darkMode })}
                  noBackground={true}
                />
                <Typography variant="h1" className="whitespace-nowrap">
                  ApeDAO
                </Typography>
              </div>
              <Typography variant="paragraph" className="text-center">
                Our decentralised application with a purpose of making Ape NFTs liquid assets.
              </Typography>
              <div className="flex flex-row items-center justify-center gap-5">
                <Link to="/marketplace">
                  <Button variant={darkMode ? 'outlined' : 'filled'} className="w-fit whitespace-nowrap">
                    To Marketplace
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-12 pt-12 dark:border-apedao-black-800 lg:gap-20 lg:pt-20">
          <div className="flex flex-col gap-5">
            <Typography variant="h1" className="text-center uppercase">
              FAQs
            </Typography>
            <Typography variant="h6" className="text-center">
              I have a question, ser...
            </Typography>
          </div>
          <div className="lg:w-3/4">
            <FaqAccordion
              title="Who is behind the ApeDAO project?"
              description={
                <Typography variant="paragraph">
                  ApeDAO was founded by <FaqLink url={links.twitterAlphaRho}>Alpha Rho</FaqLink> - a DeFi explorer and a
                  degen. His previous experience includes running his own marketing businesses and contributing to
                  ApeSwap - a DEX/AMM on BNBC. He is full time in crypto for the past 2 years and currently contributes
                  to IOTA / Shimmer ecosystem by working on ShimmerSea and ApeDAO projects.
                </Typography>
              }
              open={faqAccordionOpen === 0}
              onClick={() => setFaqAccordionOpen(faqAccordionOpen === 0 ? -1 : 0)}
            />
            <FaqAccordion
              title="What is next for ApeDAO?"
              description={
                <Typography variant="paragraph">
                  ApeDAO project is still in its infancy so the initial focus will mostly be on building sustainable
                  income streams to enable growth of the DAO. We plan to achieve this goal through our APEinvest branch
                  which mostly focuses on deploying our Treasury to various DeFi protocols.
                </Typography>
              }
              open={faqAccordionOpen === 1}
              onClick={() => setFaqAccordionOpen(faqAccordionOpen === 1 ? -1 : 1)}
            />
            <FaqAccordion
              title="Do I need to own an Ape to become a part of the community?"
              description={
                <Typography variant="paragraph">
                  Absolutely not. Anyone who will abide by our guidelines and Discord server rules is more than welcome
                  to join and benefit from the knowledge that we share on a daily basis. One of the main focuses of the
                  ApeDAO is and will always be its community, so a lot of efforts will go into further building our ape
                  community. Fun, educational, formal and informal events are just some of the ideas that we currently
                  have.
                </Typography>
              }
              open={faqAccordionOpen === 2}
              onClick={() => setFaqAccordionOpen(faqAccordionOpen === 2 ? -1 : 2)}
            />
            <FaqAccordion
              title="How does ApeDAO fund itself?"
              description={
                <Typography variant="paragraph">
                  As of this moment, ApeDAO still doesn't spend its Treasury and it will remain like that until steady
                  income stream has been built. Main driver for that will be the launch of the Shimmer EVM chain.
                </Typography>
              }
              open={faqAccordionOpen === 3}
              onClick={() => setFaqAccordionOpen(faqAccordionOpen === 3 ? -1 : 3)}
            />
            <FaqAccordion
              title="Is there a LitepAPEr which I can read?"
              description={
                <Typography variant="paragraph">
                  Yes, feel free to read our LitepAPEr by visiting{' '}
                  <FaqLink url={links.docs + '/litepaper/what-is-apedao'}>this</FaqLink> link.
                </Typography>
              }
              open={faqAccordionOpen === 4}
              onClick={() => setFaqAccordionOpen(faqAccordionOpen === 4 ? -1 : 4)}
            />
          </div>
          <div className="flex flex-col items-center gap-2">
            <Typography variant="h3" className="text-center">
              Still have questions?
            </Typography>
            <Typography variant="h6">Feel free to join our Discord server and chat with fellow degens.</Typography>
            <a href={links.discord} target="_blank" rel="noreferrer" className="mt-8">
              <Button variant={darkMode ? 'outlined' : 'filled'} className="w-fit">
                Join Discord
              </Button>
            </a>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default Home;
