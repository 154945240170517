import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { bloomWallet, metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { isDevMode } from 'helpers/storageHelpers';
import { createClient, http, webSocket } from 'viem';
import { fallback } from 'wagmi';
import {
  Chain,
  bscTestnet as viemBscTestnet,
  fantomTestnet as viemFantomTestnet,
  shimmer as viemShimmer,
  shimmerTestnet as viemShimmerTestnet,
} from 'wagmi/chains';

const walletConnectProjectId = '7b666dec5991965169b577bf122a32d5';

export interface IExtendedChain extends Chain {
  chainName: EvmChainName;
  chainIconSrc: string;
  testnet: boolean;
  lzChainId: number;
}

// --- testnets ---------------------------------------------------------------

const shimmerTestnet = {
  ...viemShimmerTestnet,
  chainName: 'shimmerEvm',
  chainIconSrc: '/images/chains/shimmerEvm.svg',
  testnet: true,
  lzChainId: 10230,
  rpcUrls: {
    default: {
      http: ['https://json-rpc.evm.testnet.shimmer.network'],
      webSocket: ['wss://ws.json-rpc.evm.testnet.shimmer.network'],
    },
  },
} as const satisfies IExtendedChain;

const fantomTestnet = {
  ...viemFantomTestnet,
  chainName: 'fantom',
  chainIconSrc: '/images/chains/fantom.svg',
  testnet: true,
  lzChainId: 10112,
  rpcUrls: {
    default: { http: ['https://fantom-testnet.publicnode.com'], webSocket: ['wss://fantom-testnet.publicnode.com'] },
  },
} as const satisfies IExtendedChain;

const bscTestnet = {
  ...viemBscTestnet,
  chainName: 'bsc',
  chainIconSrc: '/images/chains/bsc.svg',
  testnet: true,
  lzChainId: 10102,
  rpcUrls: {
    default: { http: ['https://bsc-testnet-rpc.publicnode.com'], webSocket: ['wss://bsc-testnet-rpc.publicnode.com'] },
  },
} as const satisfies IExtendedChain;

// --- mainnets ---------------------------------------------------------------

export const shimmer = {
  ...viemShimmer,
  chainName: 'shimmerEvm',
  chainIconSrc: '/images/chains/shimmerEvm.svg',
  testnet: false,
  lzChainId: 230,
  rpcUrls: {
    default: {
      http: ['https://json-rpc.evm.shimmer.network'],
      webSocket: ['wss://ws.json-rpc.evm.shimmer.network'],
    },
  },
} as const satisfies IExtendedChain;

export const mainChain = isDevMode() ? shimmerTestnet : shimmer;
export const evmChainNames = ['iotaEvm', 'shimmerEvm', 'fantom', 'bsc'];
export type EvmChainName = 'iotaEvm' | 'shimmerEvm' | 'fantom' | 'bsc';

export const supportedChainsByName: Record<EvmChainName, IExtendedChain | undefined> = {
  iotaEvm: isDevMode() ? undefined : undefined,
  shimmerEvm: isDevMode() ? shimmerTestnet : shimmer,
  fantom: isDevMode() ? fantomTestnet : undefined,
  bsc: isDevMode() ? bscTestnet : undefined,
};

export const getChainByEvmChainName = (chainName: string): IExtendedChain | undefined => {
  return evmChainNames.includes(chainName) ? supportedChainsByName[chainName as EvmChainName] : undefined;
};

export const supportedChains: IExtendedChain[] = Object.values(supportedChainsByName)
  .map((chain) => chain)
  .filter((chain) => chain !== undefined) as IExtendedChain[];

export const supportedChainsById: Record<number, IExtendedChain> = supportedChains.reduce(
  (chains, chain) => ({ ...chains, [chain.id]: chain }),
  {}
);

export const getClient = (chain: EvmChainName, useWebSocket?: boolean) => {
  const transport =
    useWebSocket && supportedChainsByName[chain]?.rpcUrls.default.webSocket ? fallback([webSocket(), http()]) : http();
  return createClient({ chain: supportedChainsByName[chain], transport });
};

export const chainsAndWallets = getDefaultConfig({
  appName: 'ApeDAO',
  projectId: walletConnectProjectId,
  chains: [mainChain, ...supportedChains.filter((chain) => chain !== mainChain)],
  wallets: [
    { groupName: 'Suggested', wallets: [bloomWallet] },
    { groupName: 'Other', wallets: [metaMaskWallet, walletConnectWallet] },
  ],
});
