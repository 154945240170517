import { AssetDisplay, StatsCard } from 'components';
import { IStats } from 'helpers/backendApi/stats_v1';
import { formatBig } from 'helpers/formatHelpers';
import { NavLink } from 'react-router-dom';
import StatsPercentageCircle from '../StatsPercentageCircle';
import useStatsMigration from './hooks/useStatsMigration';

export interface IStatsMigrationProps {
  stats: IStats;
  isDarkMode: boolean;
}

export function StatsMigration(props: IStatsMigrationProps) {
  const { data } = useStatsMigration(props);

  return (
    <StatsCard title="Migration">
      <div className="flex h-full flex-col gap-2 sm:gap-1">
        {data.map((assetData) => (
          <div key={assetData.name} className="flex flex-row justify-end gap-2">
            <AssetDisplay name={assetData.name} className="grow" />
            <div className="font-mono">{formatBig(assetData.value, 0)}</div>
            <div className="h-5 w-5 justify-self-end sm:h-6 sm:w-6">
              <StatsPercentageCircle percentage={assetData.percentage} darkMode={props.isDarkMode} />
            </div>
          </div>
        ))}
        <div className="mt-auto max-w-[32rem] pt-3 text-sm">
          Are your Apes or Bananas still bored on Shimmer L1? Let them have fun and{' '}
          <NavLink to="/migration" className="text-blue-500 underline">
            migrate now
          </NavLink>
          .
        </div>
      </div>
    </StatsCard>
  );
}

export default StatsMigration;
