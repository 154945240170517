import Big from 'big.js';
import React, { useEffect } from 'react';
import { IStatsMigrationProps } from '../StatsMigration';

export interface IStatsMigrationAssetData {
  name: string;
  value: Big;
  percentage: Big;
}

function useStatsMigration(props: IStatsMigrationProps): { data: IStatsMigrationAssetData[] } {
  const [data, setData] = React.useState<IStatsMigrationAssetData[]>();

  useEffect(() => {
    const assetStats = props.stats.assetStats;
    setData([
      {
        name: 'OG Apes',
        value: Big(assetStats.ogApe.onEvm),
        percentage: Big(assetStats.ogApe.onEvm ?? 0)
          .div(Big(assetStats.ogApe.totalSupply ?? 0))
          .mul(100),
      },
      {
        name: 'Bananas',
        value: Big(assetStats.banana.onEvm),
        percentage: Big(assetStats.banana.onEvm ?? 0)
          .div(Big(assetStats.banana.totalSupply ?? 0))
          .mul(100),
      },
    ]);
  }, [props.stats.assetStats]);

  return {
    data: data ?? [],
  };
}

export default useStatsMigration;
