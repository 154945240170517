import { isDevMode } from 'helpers/storageHelpers';

const links = {
  discord: 'https://discord.gg/WDxt6xZDDh',
  twitter: 'https://twitter.com/0xApeDAO',
  twitterAlphaRho: 'https://twitter.com/0xAlphaRho',
  medium: 'https://0xApeDAO.medium.com/',
  telegram: 'https://t.me/iotapes',
  github: 'https://github.com/iotapes',
  docs: 'https://docs.apedao.finance',
  shimmerSea: 'https://shimmersea.finance',
  shimmerSeaOgApesMarket: 'https://shimmersea.finance/nfts/collections/0xf640ed4ADFD525a3DEae9FA76a840898d61009C1',
  shimmerSeaLilApesMarket: 'https://shimmersea.finance/nfts/collections/0x3F5ae5270b404fF94BB4d2f15A4f3b46f16470D1',
  shimmerSeaLP: 'https://shimmersea.finance/liquidity/pool/0xf187872d281c001552687479c8b92a0d45f0463f',
  shimmerSeaFarm: 'https://shimmersea.finance/farms/detail/0xf187872D281C001552687479c8b92A0D45f0463F',
  accumulator: 'https://app.accumulator.finance/vaults/shimmer-shimmer-apein',
  layerZeroExplorer: isDevMode() ? 'https://testnet.layerzeroscan.com' : 'https://layerzeroscan.com',
};

export default links;
