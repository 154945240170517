import Big from 'big.js';
import React, { useEffect } from 'react';
import { IStatsPricesProps } from '../StatsPrices';

export interface IStatsPricesData {
  name: string;
  usd: Big;
  smr: Big;
  usdDecimals: number;
  smrDecimals: number;
  isToken?: boolean;
}

function useStatsPrices(props: IStatsPricesProps) {
  const [data, setData] = React.useState<(IStatsPricesData | 'delimiter')[]>();

  useEffect(() => {
    setData([
      {
        name: 'IOTA',
        usd: Big(props.stats.prices.IOTA_USD),
        usdDecimals: 6,
        smr: Big(0),
        smrDecimals: 2,
        isToken: true,
      },
      {
        name: 'SMR',
        usd: Big(props.stats.prices.SMR_USD),
        usdDecimals: 6,
        smr: Big(0),
        smrDecimals: 2,
        isToken: true,
      },
      {
        name: 'APEin',
        usd: Big(props.stats.prices.APEin_USD),
        usdDecimals: 6,
        smr: Big(props.stats.prices.APEin_SMR),
        smrDecimals: 6,
        isToken: true,
      },
      'delimiter',
      {
        name: 'Floor OG Ape',
        usd: Big(props.stats.assetStats.ogApe.floorPrice?.USD ?? 0),
        usdDecimals: 2,
        smr: Big(props.stats.assetStats.ogApe.floorPrice?.SMR ?? 0),
        smrDecimals: 2,
      },
      {
        name: "Floor Lil' Ape",
        usd: Big(props.stats.assetStats.lilApe.floorPrice?.USD ?? 0),
        usdDecimals: 2,
        smr: Big(props.stats.assetStats.lilApe.floorPrice?.SMR ?? 0),
        smrDecimals: 2,
      },
      {
        name: 'Floor Banana',
        usd: Big(props.stats.assetStats.banana.floorPrice?.USD ?? 0),
        usdDecimals: 2,
        smr: Big(props.stats.assetStats.banana.floorPrice?.SMR ?? 0),
        smrDecimals: 2,
      },
      {
        name: 'Floor Blob',
        usd: Big(props.stats.assetStats.blob.floorPrice?.USD ?? 0),
        usdDecimals: 2,
        smr: Big(props.stats.assetStats.blob.floorPrice?.SMR ?? 0),
        smrDecimals: 2,
      },
    ]);
  }, [
    props.stats.assetStats.banana.floorPrice?.SMR,
    props.stats.assetStats.banana.floorPrice?.USD,
    props.stats.assetStats.blob.floorPrice?.SMR,
    props.stats.assetStats.blob.floorPrice?.USD,
    props.stats.assetStats.lilApe.floorPrice?.SMR,
    props.stats.assetStats.lilApe.floorPrice?.USD,
    props.stats.assetStats.ogApe.floorPrice?.SMR,
    props.stats.assetStats.ogApe.floorPrice?.USD,
    props.stats.prices.APEin_SMR,
    props.stats.prices.APEin_USD,
    props.stats.prices.IOTA_USD,
    props.stats.prices.SMR_USD,
  ]);

  return data;
}

export default useStatsPrices;
