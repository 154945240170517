import Big from 'big.js';
import { useRcpBucket } from 'contexts/RcpBucket';
import { msg, useAccumulatorHoldings } from 'helpers/index';
import { LOCAL_STORAGE_ERC20_TOKEN_CACHE, LOCAL_STORAGE_POOL_ID_CACHE } from 'helpers/storageHelpers';
import React, { useCallback, useState } from 'react';
import { Client } from 'types';
import { useLocalStorage } from 'usehooks-ts';
import { Address } from 'viem';
import { readContract } from 'viem/actions';
import { useClient } from 'wagmi';
import erc20Abi from 'web3/abi/ERC20Abi';
import { mainChain } from 'web3/chainsAndWallets';
import { SHIMMERSEA_LP_DECIMALS } from 'web3/constants';
import contracts from 'web3/contracts';

export interface IUseLPAndFarmHoldingsProps {
  address?: Address;
  refreshOnInit?: boolean;
}

const useShimmerSeaLPAndFarmHoldings = (props: IUseLPAndFarmHoldingsProps) => {
  const client = useClient({ chainId: mainChain.id });
  const { throttleRequest } = useRcpBucket();

  const [holdings, setHoldings] = React.useState<{ symbol: string; value: Big }[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [initAddress, setInitAddress] = useState<Address>();

  const [poolIdCache, setPoolIdCache] = useLocalStorage<Record<Address, number>>(LOCAL_STORAGE_POOL_ID_CACHE, {});
  const [erc20TokenCache, setErc20TokenCache] = useLocalStorage<Record<Address, { symbol: string; decimals: number }>>(
    LOCAL_STORAGE_ERC20_TOKEN_CACHE,
    {}
  );

  const { getSmrAPEinLPBalance: getTokenHoldingsInAccumulator } = useAccumulatorHoldings({});

  const getTotalShimmerSeaLPSupply = useCallback(
    async (client: Client) => {
      await throttleRequest();
      const totalSupply = await readContract(client, {
        ...contracts.shimmerSeaLP,
        functionName: 'totalSupply',
      });

      await throttleRequest();
      const decimals = await readContract(client, {
        ...contracts.shimmerSeaLP,
        functionName: 'decimals',
      });

      return {
        totalSupply: Big(totalSupply.toString()),
        decimals,
      };
    },
    [throttleRequest]
  );

  const getPoolId = useCallback(
    async (client: Client): Promise<number> => {
      const poolContract = contracts.shimmerSeaLP.address;
      if (poolIdCache[poolContract]) {
        return poolIdCache[poolContract];
      }

      await throttleRequest();
      const poolLength = await readContract(client, {
        ...contracts.shimmerSeaMasterChef,
        functionName: 'poolLength',
      });

      let poolId = -1;
      for (let i = 0; i < Number(poolLength); i++) {
        await throttleRequest();
        const poolInfo = await readContract(client, {
          ...contracts.shimmerSeaMasterChef,
          functionName: 'poolInfo',
          args: [BigInt(i)],
        });

        if (poolInfo[0].toLowerCase() === poolContract.toLowerCase()) {
          poolId = i;
          break;
        }
      }

      poolIdCache[poolContract] = poolId;
      setPoolIdCache(poolIdCache);
      return poolId;
    },
    [poolIdCache, setPoolIdCache, throttleRequest]
  );

  const getTokenHoldingsInShimmerSeaLP = useCallback(
    async (client: Client, walletAddress: Address): Promise<Big> => {
      await throttleRequest();
      const lpBalance = await readContract(client, {
        ...contracts.shimmerSeaLP,
        functionName: 'balanceOf',
        args: [walletAddress],
      });

      return Big(lpBalance.toString());
    },
    [throttleRequest]
  );

  const getTokenHoldingsInShimmerSeaFarm = useCallback(
    async (client: Client, walletAddress: Address): Promise<Big> => {
      const poolId = await getPoolId(client);
      await throttleRequest();
      const userInfo = await readContract(client, {
        ...contracts.shimmerSeaMasterChef,
        functionName: 'userInfo',
        args: [BigInt(poolId), walletAddress],
      });

      return Big(userInfo[0].toString());
    },
    [getPoolId, throttleRequest]
  );

  const getLpReserves = useCallback(
    async (client: Client) => {
      await throttleRequest();
      const lpReserves = await readContract(client, {
        ...contracts.shimmerSeaLP,
        functionName: 'getReserves',
      });

      return [Big(lpReserves[0].toString()), Big(lpReserves[1].toString())];
    },
    [throttleRequest]
  );

  const getErc20TokenInfo = useCallback(
    async (client: Client, tokenAddress: Address) => {
      if (erc20TokenCache[tokenAddress]) {
        return erc20TokenCache[tokenAddress];
      }

      await throttleRequest();
      const tokenSymbol = await readContract(client, {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'symbol',
      });

      await throttleRequest();
      const tokenDecimals = await readContract(client, {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'decimals',
      });

      const erc20Token = { symbol: tokenSymbol, decimals: tokenDecimals };
      erc20TokenCache[tokenAddress] = erc20Token;

      setErc20TokenCache(erc20TokenCache);
      return erc20Token;
    },
    [erc20TokenCache, setErc20TokenCache, throttleRequest]
  );

  const getLpTokenAddresses = useCallback(
    async (client: Client) => {
      await throttleRequest();
      const lpToken0Address = await readContract(client, {
        ...contracts.shimmerSeaLP,
        functionName: 'token0',
      });

      await throttleRequest();
      const lpToken1Address = await readContract(client, {
        ...contracts.shimmerSeaLP,
        functionName: 'token1',
      });

      return [lpToken0Address, lpToken1Address];
    },
    [throttleRequest]
  );

  const refresh = React.useCallback(async () => {
    if (!props.address || !client) {
      setHoldings(undefined);
      return;
    }

    setIsLoading(true);
    try {
      const walletLpBalance = await getTokenHoldingsInShimmerSeaLP(client, props.address);
      const walletLpInFarmBalance = await getTokenHoldingsInShimmerSeaFarm(client, props.address);
      const walletLpInAccumulatorBalance = (await getTokenHoldingsInAccumulator(props.address)).mul(
        10 ** SHIMMERSEA_LP_DECIMALS
      );
      console.log({
        lpBalanceInWallet: walletLpBalance.toString(),
        lpBalanceInFarm: walletLpInFarmBalance.toString(),
        lpBalanceInAccumulator: walletLpInAccumulatorBalance.toString(),
      });
      const walletTotalLpBalance = Big(walletLpBalance.toString())
        .add(walletLpInFarmBalance)
        .add(walletLpInAccumulatorBalance);

      const totalLpSupply = await getTotalShimmerSeaLPSupply(client);
      const walletHoldingPercentage = walletTotalLpBalance.div(totalLpSupply.totalSupply);

      const lpTokenAddresses = await getLpTokenAddresses(client);
      const token1Info = await getErc20TokenInfo(client, lpTokenAddresses[0]);
      const token2Info = await getErc20TokenInfo(client, lpTokenAddresses[1]);

      const lpReserves = await getLpReserves(client);
      const walletToken1Amount = lpReserves[0].mul(walletHoldingPercentage);
      const walletToken2Amount = lpReserves[1].mul(walletHoldingPercentage);

      setHoldings([
        { symbol: token1Info.symbol, value: walletToken1Amount.div(10 ** token1Info.decimals) },
        { symbol: token2Info.symbol, value: walletToken2Amount.div(10 ** token2Info.decimals) },
      ]);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    getErc20TokenInfo,
    getLpReserves,
    getLpTokenAddresses,
    getTokenHoldingsInAccumulator,
    getTokenHoldingsInShimmerSeaFarm,
    getTokenHoldingsInShimmerSeaLP,
    getTotalShimmerSeaLPSupply,
    props.address,
    client,
  ]);

  React.useEffect(() => {
    if (props.address && props.address !== initAddress && (props.refreshOnInit ?? true)) {
      setInitAddress(props.address);
      refresh().catch(() => msg.error(`Error fetching account LP and farm holdings`));
    }
  }, [initAddress, props.address, props.refreshOnInit, refresh]);

  return {
    holdings,
    refresh,
    isLoading,
    isError,
  };
};

export default useShimmerSeaLPAndFarmHoldings;
