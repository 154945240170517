import { useWalletInfo } from 'contexts/WalletInfo';
import { useNftContractAbstraction, useShimmerSeaNftMarket } from 'helpers';
import { INft as INftBackend, INftResponse } from 'helpers/backendApi/nft_v1';
import { httpGet } from 'helpers/fetch';
import { isDevMode } from 'helpers/storageHelpers';
import { COLLECTIONS } from 'helpers/useNftCollections';
import { ICollectionMarketListing } from 'helpers/useShimmerSeaNftMarket';
import { useCallback } from 'react';
import { ICollection, INftLocation, NftLocationTag } from 'types';
import { Address } from 'viem';
import { supportedChains } from 'web3/chainsAndWallets';
import contracts, { contractAddresses } from 'web3/contracts';

const backendNftsUrl = isDevMode()
  ? 'https://backend-testnet.apedao.finance/v1/nft/'
  : 'https://backend.apedao.finance/v1/nft/';

const useNftLocations = () => {
  const walletInfo = useWalletInfo();
  const { nftsOnMarket: nftsOnShimmerSeaMarket } = useShimmerSeaNftMarket({});
  const { getOwnerOf } = useNftContractAbstraction();

  const getTag = useCallback(
    (
      collectionId: number,
      nftEdition: number,
      address: Address | 'n/a',
      chain: string | 'shimmerL1'
    ): NftLocationTag => {
      const shimmerSeaMarketListings = nftsOnShimmerSeaMarket?.get(collectionId) ?? [];

      let tag: NftLocationTag;
      if (
        shimmerSeaMarketListings.find((nftOnMarket: ICollectionMarketListing) => nftOnMarket.nftEdition === nftEdition)
      ) {
        tag = 'shimmerSeaMarket';
      } else if (address === walletInfo.address) {
        tag = 'wallet';
      } else if (address === contracts.apeDaoVault.address) {
        tag = 'vault';
      } else if (address !== contractAddresses.apeDaoMigrationWallet && chain !== 'shimmerL1') {
        tag = 'foreign_wallet';
      } else {
        tag = 'L1';
      }

      return tag;
    },
    [nftsOnShimmerSeaMarket, walletInfo.address]
  );

  const toNftLocation = useCallback(
    (nft: INftBackend): INftLocation => {
      return {
        chain: nft.location.chain,
        address: nft.location.address ?? 'n/a',
        tag: getTag(nft.collection.id, nft.edition, nft.location.address ?? 'n/a', nft.location.chain),
      };
    },
    [getTag]
  );

  const getNftLocationsFromBackend = useCallback(
    async (collection: ICollection): Promise<INftLocation[]> => {
      const nftResponse = await httpGet<INftResponse>(`${backendNftsUrl}${collection.backendKey}`);
      return nftResponse.parsedBody?.data.nfts.map(toNftLocation) ?? [];
    },
    [toNftLocation]
  );

  const getNftLocation = useCallback(
    async (nftId: string): Promise<INftLocation> => {
      const [collectionId, edition] = nftId.split('-').map(Number);
      const shimmerSeaMarketListings = nftsOnShimmerSeaMarket?.get(collectionId) ?? [];

      const marketListing =
        nftsOnShimmerSeaMarket &&
        shimmerSeaMarketListings.find((marketListing) => marketListing.nftEdition === edition);

      if (marketListing) {
        return {
          chain: 'shimmerEvm',
          address: marketListing.currentSeller,
          tag: 'shimmerSeaMarket',
        };
      } else {
        const collection = COLLECTIONS.get(collectionId)!;
        for (const chain of supportedChains) {
          try {
            const nftContractAddress = collection.contracts[chain.chainName]?.address;
            const lzProxyAddress = collection.contracts[chain.chainName]?.lzProxyAddress;
            const ownerAddress = await getOwnerOf(chain.chainName, collection, edition);
            if (ownerAddress && ownerAddress !== lzProxyAddress && ownerAddress !== nftContractAddress) {
              return {
                chain: chain.chainName,
                address: ownerAddress,
                tag: getTag(collectionId, edition, ownerAddress, chain.chainName),
              };
            }
          } catch (error) {
            console.error('Error fetching owner of nft', error);
          }
        }
      }

      return {
        chain: 'n/a',
        address: 'n/a',
        tag: 'n/a',
      };
    },
    [getOwnerOf, getTag, nftsOnShimmerSeaMarket]
  );

  return {
    getNftLocationsFromBackend,
    getNftLocation,
  };
};

export default useNftLocations;
