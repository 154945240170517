import Big from 'big.js';
import { assetStatsKeyNameMapping, CollectionKey, IStats, TokenKey } from 'helpers/backendApi/stats_v1';
import React, { useEffect } from 'react';
import { IStatsMarketCapProps } from '../StatsMarketCap';

export interface IStatsMarketCapAssetData {
  name: string;
  L1: Big;
  evm: Big;
  total: Big;
}

function useStatsMarketCap(props: IStatsMarketCapProps) {
  const [data, setData] = React.useState<{ total: Big; assets: IStatsMarketCapAssetData[] }>();

  useEffect(() => {
    const assets = Object.keys(props.stats.assetStats)
      .map((key: string) => {
        const assetStats = props.stats.assetStats[key as keyof IStats['assetStats']];
        const marketCapL1 = Big(assetStats.marketCapInUSD?.L1 ?? 0);
        const marketCapEvm = Big(assetStats.marketCapInUSD?.evm ?? 0);

        return {
          name: assetStatsKeyNameMapping[key as CollectionKey | TokenKey],
          L1: marketCapL1,
          evm: marketCapEvm,
          total: marketCapL1.add(marketCapEvm),
        };
      })
      .filter((asset) => asset.L1.gt(0) || asset.evm.gt(0));

    const total = assets.reduce((prev, current) => {
      return prev.add(current.evm).add(current.L1);
    }, Big(0));

    setData({
      total: total,
      assets: assets,
    });
  }, [props.stats.assetStats]);

  return data;
}

export default useStatsMarketCap;
